<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>
				<Toolbar class="p-mb-0">
					<template #left>
					</template>
				</Toolbar>


                <!-- Táblázat -->

				<DataTable ref="dt" :value="users" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            v-model:expandedRows="expandedRows" @row-click="expandRow" :rowClass="rowClass"
                            sortField="name" :sortOrder="1" :loading="loading" @filter="onFilter($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Felhasználók</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="role_value" header="Szerepkör" sortable></Column>
					<Column field="title" header="Titulus" sortable></Column>
					<Column field="name" header="Név" sortable></Column>
					<Column field="email" header="Email" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" 
                                @click="editUser(slotProps.data.id)" />
							<Button icon="pi pi-key" class="p-button-rounded p-button-warning p-mr-2" 
                                v-tooltip.top="'Belépéshez és jelszó-megadáshoz link küldése az email-címre'"
                                @click="confirmSendPassword(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" 
                                @click="confirmDeleteUser(slotProps.data.id)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Telefonszám:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="displayTels(slotProps.data.tel1, slotProps.data.tel2)"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.city }}</div-->
                            </div>
                            <DataTable v-if="slotProps.data.role!=='PHARMACY'"
                                        ref="dt_phar" :value="slotProps.data.phars" dataKey="id" 
                                        sortField="name" :sortOrder="1"
                                        class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows l-datatable-nar-rows">
                                <template #header>
                                    <div class="table-header">
                                        <div>
                                            <Button label="Új" icon="pi pi-plus" class="p-button-primary p-mr-2" @click="openPharNew(slotProps.data.id)" />
                                            <h5 class="p-m-0 p-d-inline-block">Hozzárendelt patikák</h5>
                                        </div>
                                    </div>
                                </template>

                                <Column field="name" header="Megnevezés" sortable></Column>
                                <Column>
                                    <template #body="slotProps2">
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" @click="confirmDeletePhar(slotProps.data.id, slotProps2.data.id)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </template>                            
                        </Card>
                    </template>				
				</DataTable>


                <!-- Szerkesztő ablak - User -->

				<Dialog id="dialog" v-model:visible="userDialog" :style="{width: '450px', 'margin-top': '16px' }"
                    header="Felhasználó" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveUser()" @keyup.esc="hideDialog()">
					<div class="p-field">
                        <SelectButton v-model="user.role" 
                            required="true" :v="v$.user.role" :class="{'p-invalid': v$.user.role.$invalid }" @blur="v$.user.role.$touch"
                            :options="codesUserRole" optionLabel="value" optionValue="code"
                        />
						<small class="p-invalid" v-for="e of v$.user.role.$errors" :key="e.$uid">{{ e.$message }}</small>
					</div>
					<span class="p-field p-float-label">
						<InputText id="name" v-model.trim.lazy="user.name" :v="v$.user.name" @blur="v$.user.name.$touch"
                            required="true" :class="{'p-invalid': v$.user.name.$invalid}" autocomplete="new-user-name"
                        />
						<label for="name">Név</label>
						<small class="p-invalid" v-for="e of v$.user.name.$errors" :key="e.$uid">{{ e.$message }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="email" v-model.trim.lazy="user.email" :v="v$.user.email" 
                            @blur="v$.user.email.$touch" @input="userServerResponse.email = 1; v$.userServerResponse.email.$touch"
                            :class="{'p-invalid': v$.user.email.required.$invalid || v$.user.email.$error}"  autocomplete="new-user-email"
                        />
						<label for="email">Email</label>
						<small class="p-invalid" v-for="e of v$.user.email.$errors" :key="e.$uid">{{ e.$message }}</small>
						<small class="p-invalid" v-if="v$.userServerResponse.email.$error">{{ errorMessages.existingEmail }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText v-model.trim="user.title" autocomplete="new-user-title"
                        />
						<label>Titulus</label>
					</span>
					<span class="p-field p-float-label">
						<InputText v-model.trim="user.tel1" autocomplete="new-user-tle1"
                        />
						<label>Telefonszám</label>
					</span>
					<span class="p-field p-float-label">
						<InputText v-model.trim="user.tel2" autocomplete="new-user-tle2"
                        />
						<label>Telefonszám 2</label>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="user.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>
                    <span v-if="!user.id">
                        <Checkbox class="p-mt-3" id="sendPassword" :binary="true" v-model="user.sendPassword" />
                        <label for="sendPassword" style="cursor:pointer"> Belépéshez és jelszó-megadáshoz link küldése az email-címre</label>
                    </span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveUser"/>
					</template>
				</Dialog>

                <!-- Szerkesztő ablak - Phar -->

				<Dialog id="pharDialog" v-model:visible="pharDialog" :style="{width: '450px', 'margin-top': '16px' }"
                    header="Felhasználóhoz rendelt patika" :modal="true" class="p-fluid" :contentStyle="{'overflow': 'visible'}"
                    @show="dialogPharShow" @keydown.enter.ctrl="savePhar()" @keyup.esc="hidePharDialog()">
					<span class="p-field p-float-label" style="margin-top: 1rem">
                        <Dropdown ref="firstPharEditField" v-model="phar.pharmacyid" :options="pharmacies" optionLabel="name" optionValue="id" placeholder=""
                            required="true" :class="{'p-invalid': v$.phar.pharmacyid.$invalid}" :v="v$.phar.pharmacyid" @change="v$.phar.pharmacyid.$touch"
                        />
						<label style="top: 1px; font-size: 12px;">Patika</label>
						<small class="p-invalid" v-if="vCheckPharRequire('pharmacyid')">{{ errorMessages.required }}</small>
					</span>
                    <div style="margin-bottom: 1rem"></div>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hidePharDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="savePhar"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="user">Biztosan töröljük az felhasználót: <b>{{user.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            @click="deleteUserDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteUser"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePharDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="user">Leválasszuk a patikát: <b>{{phar.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            @click="deletePharDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deletePhar"/>
					</template>
				</Dialog>


                <!-- Jelszó-küldés kérdés felugró -->

				<Dialog v-model:visible="sendPasswordDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="user">Küldjünk emailben a belépési linket?<br/>
                                        Felhasználó: <b>{{user.name}}</b>
                        </span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            @click="sendPasswordDialog = false"/>
						<Button label="Küldés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-key'" class="p-button-success" 
                            @click="sendPasswordUser"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UsersService from '@/service/backend/users.service'
import useVuelidate from '@vuelidate/core'
import { minValue } from '@vuelidate/validators'
import { required, email, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			users: null,
			userDialog: false,
			deleteUserDialog: false,
            sendPasswordDialog: false,
            user: {},
            userServerResponse: { email: 1 },
            filters: {
                'global': {value: null},
            },

            phars: {},
            phar: {},
			pharDialog: false,
			deletePharDialog: false,

            pharmacies: [],

            submitted: false,
            checked: false,
            searchGlobal: "",
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

            expandedRows: []

		}
	},
    validations: {
        user: {
            name: { required },
            email: { required, email },
            role: { required }
        },
        userServerResponse: {
            email: { minValue: minValue(1) }
        },
        phar: {
            pharmacyid: { required }
        }
    },
    usersService: null,
	created() {
		this.usersService = new UsersService()
    },
    beforeMount() {
        //this.loadCodeStore('userRoles') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	async mounted() {
        this.usersService.getUsers().then( data => {
            //console.log(data)
            data.forEach( d => {d.role_value = this.codesUserRole.find(c => c.code === d.role).value; } ) // Itt töltjük fel, hogy megjelenítéskor legyen csak - TODO valahogy DRY-olni KISS-elni
            this.users = data
            this.loading = false
        } );
	},
	methods: {
        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }),
        vCheckRequire(field){
            return this.v$.user[field].$dirty && !this.v$.user[field].required
        },
        vCheckPharRequire(field){
            return this.v$.phar[field].$dirty && !this.v$.phar[field].required
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.users.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        rowClass(data) {
            return (data.expanded) ? 'l-expanded' : ''
        },
        openDialog(){
            this.userDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.user = { sendPassword: true }
            this.openDialog()
		},
		editUser(id) {
            this.user = JSON.parse(JSON.stringify(this.users.find( d => d.id === id)));
            this.openDialog()
        },
		hideDialog() {
			this.userDialog = false
            this.submitted = false
		},
		async saveUser() {

            this.submitted = true;
            this.v$.user.$touch()
            this.userServerResponse.email = 1

            if (!this.v$.user.$invalid) {

                    try{
                        if (this.user.id) {
                            await this.usersService.updateUser(this.user)
                            this.users[this.findIndexById(this.user.id)] = this.user
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve', life: 3000})
                        }
                        else {
                            const response = await this.usersService.newUser(this.user)
                            this.user.id = response.id
                            this.user.phars = []
                            this.users.unshift(this.user)
                            let message = "Létrehozva"
                            if(response.emailSent) message+= ", email kiküldve"; 
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: message, life: 3000})
                        }
                    }catch(error){
                        let message = error.response.message
                        if(error.response.status === 422 && 
                            (error.response.data.errors.email[0] === "The email has already been taken." ||
                             error.response.data.errors.email[0] === "A(z) email már foglalt."))
                        {  
                                this.userServerResponse.email = 0; this.v$.userServerResponse.$touch()
                                message = this.errorMessages.existingEmail
                        }
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: message, life: 3000})
                        this.submitted = false
                        return
                    }

                    // Kódtár alapján érték beállítása
                    this.user.role_value = this.codesUserRole.find(c => c.code === this.user.role).value 

                    this.hideDialog()
            
            }

            this.submitted = false

		},
        confirmSendPassword(id) {
			this.user = this.users.find( d => d.id === id)
			this.sendPasswordDialog = true
		},
		confirmDeleteUser(id) {
			this.user = this.users.find( d => d.id === id)
			this.deleteUserDialog = true
		},
		async deleteUser() {
            this.submitted = true
            try{
                await this.usersService.deleteUser(this.user.id)
                this.users = this.users.filter(val => val.id !== this.user.id)
                this.deleteUserDialog = false
                this.user = {}
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Felhasználó törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
        },
        async sendPasswordUser(){
            this.submitted = true
            try{
                let response = await this.usersService.sendPasswordUser(this.user.email)
                if(response.data.emailSent){
                    this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Email kiküldve!', life: 3000})
                    this.sendPasswordDialog = false
                }else{
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Email küldés SIKERTELEN!', life: 3000})
                }
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
        },
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].id === id) {
					index = i
					break
				}
			}

			return index;
		},
        onFilter() {
            this.loadingSearch = false
        },
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
        async loadPharmacySelect() {
            try{
                this.pharmacies = await this.usersService.getPharmaciesForSelect()
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a patikák betöltésénél', life: 3000})
            }
        },
        openPharDialog() {
            this.loadPharmacySelect()
            this.submitted = false
            this.pharDialog = true
            this.$nextTick(() => { this.v$.$reset() })
        },
        dialogPharShow() {
            setTimeout(() => { this.$refs.firstPharEditField.$el.focus() }, 100)
        },
		openPharNew(carerid) {
            this.phar = { carerid }
            this.openPharDialog()
		},
		editPhar(id) {
            this.phar = JSON.parse(JSON.stringify(this.expandedRows[0].phars.find( d => d.id === id)));
            this.openPharDialog()
		},
		hidePharDialog() {
			this.pharDialog = false
            this.submitted = false
		},
		async savePhar() {

            this.submitted = true;
            this.v$.phar.$touch()

            if (!this.v$.phar.$invalid) {
                    try{
                        this.phar.id = await this.usersService.newPhar(this.phar)
                        const pharid = this.phar.pharmacyid
                        this.phar.name = this.pharmacies.find( p => { return p.id === pharid}).name
                        const userIndex = this.findIndexById(this.phar.carerid)
                        this.users[userIndex].phars.push(this.phar)
                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                        this.submitted = false
                        return
                    }

                    this.hidePharDialog()
            }

            this.submitted = false

		},
		confirmDeletePhar(carerid, pharmacyid) {
			this.phar = {carerid, pharmacyid}
			this.deletePharDialog = true
		},
		async deletePhar() {
            this.submitted = true
            try{
                await this.usersService.deletePhar(this.phar)
                const userIndex = this.findIndexById(this.phar.carerid)
                this.users[userIndex].phars = this.users[userIndex].phars.filter(p => p.id !== this.phar.pharmacyid )
                this.deletePharDialog = false
                this.phar = {}
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Patika leválasztva!', life: 3000})
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
        displayTels(v1, v2){
            let v = (v1 === undefined) ? '' : v1
            v+= (v1!=='' && v2!=='')?'; ':'' 
            v+= (v2 === undefined) ? '' : v2
            return v
        }
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'].value = val }, 20)
            }, 600)
        }
    },
    computed: {
        ...mapGetters({
            codesUserRole: 'codeStore/userRoles'
        })
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
.l-datatable-nar-rows td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.l-expanded {
    background-color: #607d8b33 !important;
}

</style>

<style scoped>
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.user-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}


#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

</style>
<style scoped lang="postcss">
.user-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}

</style>
